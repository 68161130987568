import Vue from 'vue'

export function setOrders (state, orders) {
  state.orders = orders
}

export function addOrders (state, orders) {
  state.orders.orders.push(...orders.orders)
  state.orders.self = orders.self
  state.orders.next = orders.next
}

export function setOrder (state, order) {
  state.orderDetail = order
}

export function updateOrderInList (state, { order, index }) {
  Vue.set(state.orders.orders, index, order)
}

export function setOrderProperty (state, { property, value }) {
  Vue.set(state.orderDetail, property, value)
}
