export function showNotification (state, config) {
  if (!config || !config.message) {
    return
  }

  for (const key in state.config) {
    if (config[key] !== undefined) {
      state.config[key] = config[key]
    }
  }

  state.show = true
}

export function closeNotification (state) {
  state.show = false
}

export function setNotifications (state, notifications) {
  state.notifications = notifications
}

export function addNotifications (state, notifications) {
  state.notifications.notifications.push(...notifications.notifications)
  state.notifications.self = notifications.self
  state.notifications.next = notifications.next
}

export function addNotification (state, notification) {
  if (!state.notifications) {
    state.notifications = { notifications: [] }
  }

  state.notifications.notifications.unshift(notification)
}
