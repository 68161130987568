import Vue from 'vue'
import { buildListURL } from '@/lists/lists'

export async function getProducts ({ commit }, search = undefined) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setProducts', null)

    const url = buildListURL('/v1/products', search)
    const { data } = await Vue.axios({
      url
    })

    commit('setProducts', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getMoreProducts ({ commit }, url) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      url
    })

    commit('addProducts', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getProduct ({ commit }, productID) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setProduct', null)

    const { data } = await Vue.axios({
      method: 'GET',
      url: `/v1/products/${productID}`
    })

    commit('setProduct', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function createProduct ({ commit }, product) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'POST',
      url: '/v1/products',
      data: product
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function updateProduct ({ commit }, product) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'PUT',
      url: `/v1/products/${product.product_id}`,
      data: product
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}
