<template>
  <v-snackbar class="notification-popup"
    v-model="showNotification"
    :color="config.color"
    :timeout="config.timeout"
    :top="config.y === 'top'"
    :bottom="config.y === 'bottom'"
    :left="config.x === 'left'"
    :right="config.x === 'right'"
    :multi-line="config.mode === 'multi-line'"
    :vertical="config.mode === 'vertical'">
    <span class="message">{{ config.message }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn class="text-none" icon :color="config.buttonColor" v-bind="attrs" @click="closeNotification">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('notification', ['show', 'config']),
    showNotification: {
      get: function () {
        return this.show
      },
      set: function (value) {
        if (!value) {
          this.closeNotification()
        }
      }
    }
  },
  methods: {
    ...mapActions('notification', ['closeNotification'])
  }
}
</script>
<style scoped>
.notification-popup >>> .v-snack__content {
  max-width: 26.25rem;
}
.message {
  color: #FFFFFF;
}
</style>
