import Vue from 'vue'

export async function getAuthorization ({ commit }) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setAuthorization', null)

    const { data } = await Vue.axios({
      method: 'GET',
      url: '/v1/payment-gateway'
    })

    commit('setAuthorization', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function confirmAuthorization ({ commit }, linkApplication) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'POST',
      url: '/v1/payment-gateway',
      data: linkApplication
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}
