export function changeDrawer ({ commit }) {
  commit('changeDrawer')
}

export function setDrawer ({ commit }, drawer) {
  commit('setDrawer', drawer)
}

export function changeMini ({ commit }) {
  commit('changeMini')
}

export function activateMenuItem ({ commit }, routeName) {
  commit('activateMenuItem', routeName)
}

export function deactivateMenuItem ({ commit }, routeName) {
  commit('deactivateMenuItem', routeName)
}
