import Vue from 'vue'

export function showNotification ({ commit }, config) {
  commit('showNotification', config)
}

export function closeNotification ({ commit }) {
  commit('closeNotification')
}

export async function getNotifications ({ commit }) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setNotifications', null)

    const { data } = await Vue.axios({
      url: '/v1/notifications'
    })

    commit('setNotifications', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getMoreNotifications ({ commit }, url) {
  try {
    commit('shared/setError', null, { root: true })

    const { data } = await Vue.axios({
      url
    })

    commit('addNotifications', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  }
}

export async function updateNotification ({ commit }, notification) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'PUT',
      url: `/v1/notifications/${notification.notification_id}`,
      data: notification
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}
