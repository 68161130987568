export function setRegions (state, regions) {
  state.regions = regions
}

export function setCities (state, cities) {
  state.cities = cities
}

export function setDeliveryZones (state, deliveryZones) {
  state.deliveryZones = deliveryZones
}

export function addDeliveryZones (state, deliveryZones) {
  state.deliveryZones.delivery_zones.push(...deliveryZones.delivery_zones)
  state.deliveryZones.self = deliveryZones.self
  state.deliveryZones.next = deliveryZones.next
}

export function setDeliveryZone (state, deliveryZone) {
  state.deliveryZoneDetail = deliveryZone
}
