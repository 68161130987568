export function authenticationIsRequired (to, isAuthenticated) {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  return requiresAuth && !isAuthenticated
}

export function hideIfAuthenticated (to, isAuthenticated) {
  const hideInSession = to.matched.some(record => record.meta.hideInSession)

  return isAuthenticated && hideInSession
}

export function validateChangePassword (to) {
  if (to.name !== 'change-password') {
    return true
  }

  return !!to.query.email && !!to.query.token
}
