export function setProducts (state, products) {
  state.products = products
}

export function addProducts (state, products) {
  state.products.products.push(...products.products)
  state.products.self = products.self
  state.products.next = products.next
}

export function setProduct (state, product) {
  state.productDetail = product
}
