import Vue from 'vue'
import { buildListURL } from '@/lists/lists'
import { PAYMENT_STATUS_SUCCESS, PAYMENT_STATUS_PENDING_DELIVERY, PAYMENT_STATUS_REFUND } from '@/shared/payment'

export async function getOrders ({ commit }, search = undefined) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setOrders', null)

    const url = buildListURL(getOrderListURL(), search)
    const { data } = await Vue.axios({
      url
    })

    commit('setOrders', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

function getOrderListURL () {
  return `/v1/orders?statuses=${PAYMENT_STATUS_SUCCESS}&statuses=${PAYMENT_STATUS_PENDING_DELIVERY}&statuses=${PAYMENT_STATUS_REFUND}`
}

export async function getMoreOrders ({ commit }, url) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      url
    })

    commit('addOrders', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getOrder ({ commit }, orderID) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setOrder', null)

    const { data } = await Vue.axios({
      method: 'GET',
      url: `/v1/orders/${orderID}`
    })

    commit('setOrder', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function createDeliveryRecord ({ commit }, { orderID, deliveryRecord }) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'POST',
      url: `/v1/orders/${orderID}/delivery`,
      data: deliveryRecord
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function updateDeliveryRecord ({ commit }, { orderID, deliveryRecord }) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'PUT',
      url: `/v1/orders/${orderID}/delivery`,
      data: deliveryRecord
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function queryOrders ({ commit }, queryType) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setOrders', null)

    const url = `/v1/orders/queries?query_type=${queryType}`
    const { data } = await Vue.axios({
      url
    })

    commit('setOrders', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function generateGuide ({ commit }, orderID) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'POST',
      url: `/v1/orders/${orderID}/guide`
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export function updateOrderInList ({ commit }, data) {
  commit('updateOrderInList', data)
}

export function setOrderProperty ({ commit }, data) {
  commit('setOrderProperty', data)
}
