import Vue from 'vue'
import { buildListURL } from '@/lists/lists'

export async function getCategories ({ commit }, search = undefined) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setCategories', null)

    const url = buildListURL('/v1/categories', search)
    const { data } = await Vue.axios({
      url
    })

    commit('setCategories', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getCategory ({ commit }, categoryID) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setCategory', null)

    const { data } = await Vue.axios({
      method: 'GET',
      url: `/v1/categories/${categoryID}`
    })

    commit('setCategory', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}
