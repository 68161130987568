import Vue from 'vue'

export function addUploadFile ({ commit }, file) {
  commit('addUploadFile', file)
}

export function deleteUploadFile ({ commit }, file) {
  commit('deleteUploadFile', file)
}

export async function generateUploadUrls ({ commit }, uploadUrlInput) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'POST',
      url: '/v1/files',
      data: uploadUrlInput
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function saveFiles ({ state, commit }, uploadUrlInput) {
  try {
    const uploadUrls = await generateUploadUrls({ commit }, uploadUrlInput)

    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    for (const uploadUrl of uploadUrls.urls) {
      const file = state.files.find(element => element.name === uploadUrl.name)
      if (!file) {
        continue
      }

      await Vue.axios({ method: 'PUT', url: uploadUrl.url, data: file.file })
    }
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}
