export default {
  show: false,
  config: {
    color: '',
    mode: '',
    message: '',
    timeout: 60000,
    x: 'left',
    y: 'bottom',
    buttonColor: '#669DF6'
  },
  notifications: null
}
