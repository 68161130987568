export function setCollections (state, collections) {
  state.collections = collections
}

export function addCollections (state, collections) {
  state.collections.collections.push(...collections.collections)
  state.collections.self = collections.self
  state.collections.next = collections.next
}

export function setCollection (state, collection) {
  state.collectionDetail = collection
}
