import axios from 'axios'
import { BASE_API, API_TOKEN_NAME, STORAGE_TOKEN_NAME } from '@/config/constants'

// const CancelToken = axios.CancelToken
// let cancel = null

axios.defaults.baseURL = BASE_API
axios.interceptors.request.use(
  config => {
    // if (cancel) {
    //   cancel('Operation canceled by the user.')
    // }

    const token = localStorage.getItem(STORAGE_TOKEN_NAME)
    if (token) {
      config.headers[API_TOKEN_NAME] = token // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcl9pZCI6IkNUUmU1NDUxMzNlN2NjOGEyNDBjOTAxNmRjYTU4YjQyYWVlIiwiZXhwIjozMTc2MDY2NDQ0LCJncmFudCI6Im1hc3RlciIsImlhdCI6MTU5OTI2NjQ0NCwiaWQiOiJjN2IzMGM3ZC0wY2RhLTQ4YzctODVhOS0xZWExZDdkZTY5YmIiLCJpc3MiOiJEZXZlbG9waXQiLCJ1c2VyX2lkIjoiIiwidXNlcm5hbWUiOiJlbWFpbEBlbWFpbC5jb20ifQ.z007n2X_L87yESKXZQOnphA7Ae_29_SCJHsXfREJi10'
    }

    // config.cancelToken = new CancelToken(function executor (c) {
    //   cancel = c
    // })

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default axios
