import { currency } from '@/shared/format'
import productNotFound from '@/assets/product-not-found.png'

export function getHumanizedPrice (state) {
  return function (index) {
    const product = state.products.products[index]
    if (product.price) {
      return currency(product.price)
    }

    if (!product.variants) {
      return ''
    }

    const minPrice = Math.min.apply(Math, product.variants.map(function (o) { return o.price }))
    const maxPrice = Math.max.apply(Math, product.variants.map(function (o) { return o.price }))

    if (minPrice === maxPrice) {
      return currency(maxPrice)
    }

    return currency(minPrice) + ' ~ ' + currency(maxPrice)
  }
}

export function getHumanizedQuantity (state) {
  return function (index) {
    const product = state.products.products[index]
    if (product.quantity_in_stock) {
      return humanizedQuantity(product.quantity_in_stock)
    }

    if (!product.variants) {
      return 0
    }

    const quantity = product.variants.map(variant => variant.quantity_in_stock).reduce((quantity, amount) => quantity + amount)

    return humanizedQuantity(quantity)
  }
}

function humanizedQuantity (quantity) {
  if (quantity === 0) {
    return quantity
  }

  const text = quantity === 1 ? 'Unidad' : 'Unidades'

  return quantity + ' ' + text
}

export function getCoverImage (state) {
  return function (index) {
    const product = state.products.products[index]
    if (hasImages(product)) {
      return product.image_urls[0]
    }

    if (!product.variants) {
      return productNotFound
    }

    for (const variant of product.variants) {
      if (hasImages(variant)) {
        return variant.image_urls[0]
      }
    }

    return productNotFound
  }
}

function hasImages (product) {
  const images = product.image_urls

  return !!images && images.length > 0
}
