<template>
  <v-app id="app">
    <notification-popup></notification-popup>
    <router-view />
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import NotificationPopup from '@/components/Shared/NotificationPopup'

export default {
  components: {
    NotificationPopup
  },
  computed: {
    ...mapState('auth', ['isAuthenticated'])
  },
  methods: {
    ...mapActions('websocket', ['createConnection'])
  },
  watch: {
    isAuthenticated: function (value) {
      if (value) {
        this.createConnection()
      }
    }
  },
  created () {
    if (this.isAuthenticated) {
      this.createConnection()
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Proxima Nova, -apple-system, Helvetica Neue, Helvetica,Roboto, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1rem;
  color: #000000DE;
}

:root {
  --app-menu-bg-color: #FFFFFFFF;
  // --app-main-bg-color: #F5F5F5; #222222
  --app-main-bg-color: #F5F7FA;

  --app-menu-bg-color-active: #E8F0FEFF;
  --app-menu-color-active: #1A73E8FF;
  --app-menu-title-color: #000000A6;
  --app-menu-item-color: #202124FF;
  --app-notification-title-color: #3C4043FF;
  --app-notification-message-color: #5F6368FF;
  --app-icon-color: #707070FF;

  --app-popup-bg-color-success: #26A69AFF;
  --app-popup-bg-color-danger: #EA615CFF;

  --app-icon-inactive-color: #BDBDBDFF;
  --app-icon-active-color: #2196F3FF;

  --app-text-color: #333333FF;
  --app-button-bg-color: #2B81D6FF;
  --app-button-bg-color-success: #26A69AFF;
  --app-button-bg-color-failure: #EA615CFF;
  --app-floated-button-bg-color: #333333FF;

  --app-progress-circular-color: #565656FF;
  --app-avatar-bg-color: #1976D2FF;
  --app-avatar-color: #FFFFFFFF;

  --app-navigation-drawer-border: #3C40434C 0 3.125rem 0.125rem 0, #3C404326 0 3.125rem 0.188rem 0.063rem;
  --app-toolbar-border: #3C40434C 0 0.063rem 0.125rem 0, #3C404326 0 0.125rem 0.375rem 0.125rem;

  --app-color-delivery-returned: #F57C00FF;
  --app-color-delivery-delivered: #388E3CFF;
}

@keyframes autofill {
  0%,100% {
    color: inherit;
    background: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  animation-delay: 1s;
  animation-name: autofill;
  animation-fill-mode: both;
}
.first-capital-letter::first-letter{
  text-transform: uppercase;
}
.app-nav-bottom-divider {
  border-bottom: 0.063rem solid #dadce0;
}
.app-nav-top-divider {
  border-top: 0.063rem solid #dadce0;
}
.btn-default {
  width: 20rem;
  background-color: var(--app-button-bg-color) !important;
}
.btn-default > .v-btn__content {
  color: #FFFFFF;
}
.btn-default > .v-btn__loader {
  color: #FFFFFF;
}
.btn-icon-default {
  background-color: var(--app-button-bg-color) !important;
}
.btn-icon-default > .v-btn__content {
  color: #FFFFFF;
}
.app-border-top {
  border-top: 0.063rem solid;
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.app-border-bottom {
  border-bottom: 0.063rem solid;
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.list-item-title {
  text-align: left;
  text-transform: lowercase;
  font-weight: 500;
  font-size: 1.125rem;
  margin-top: 0.25rem;
}
.list-item-subtitle {
  text-align: left;
  font-size: 0.875rem;
  letter-spacing: 0.0178571429rem;
  line-height: 1.25rem;
}
.payment-pending {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.55) !important;
}
.payment-in_progress {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.55) !important;
}
.payment-success {
  font-weight: 700;
  color: #388E3CFF !important;
}
.payment-failure {
  font-weight: 700;
  color: #FA113DFF !important;
}
.payment-expired {
  font-weight: 700;
  color: #FA113DFF !important;
}
.payment-cancelled {
  font-weight: 700;
  color: #FA113DFF !important;
}
.payment-pending_delivery {
  font-weight: 700;
  color: #3483FAFF !important;
}
.payment-refund {
  font-weight: 700;
  color: #FB8C00FF !important;
}
/* 600px */
@media only screen and (max-width: 37.5rem) {
  .btn-default {
    width: 100%;
  }
}
</style>
