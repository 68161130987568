import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import account from '@/store/modules/account'
import auth from '@/store/modules/auth'
import menu from '@/store/modules/menu'
import notification from '@/store/modules/notification'
import shared from '@/store/modules/shared'
import category from '@/store/modules/category'
import product from '@/store/modules/product'
import paymentgateway from '@/store/modules/payment-gateway'
import delivery from '@/store/modules/delivery'
import order from '@/store/modules/order'
import websocket from '@/store/modules/websocket'
import file from '@/store/modules/file'
import theme from '@/store/modules/theme'
import collection from '@/store/modules/collection'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: localStorage,
  modules: ['auth']
})

export default new Vuex.Store({
  modules: {
    account,
    auth,
    menu,
    notification,
    shared,
    category,
    product,
    paymentgateway,
    delivery,
    order,
    websocket,
    file,
    theme,
    collection
  },
  plugins: [vuexLocal.plugin]
})
