export function setTheme (state, theme) {
  initThemeBanners(theme)

  state.themeDetail = theme
}

function initThemeBanners (theme) {
  if (!theme) {
    return
  }

  if (!theme.banner_images) {
    theme.banner_images = []
  }

  if (!theme.mobile_banner_images) {
    theme.mobile_banner_images = []
  }
}

export function setDefaultTheme (state) {
  state.themeDetail = {
    logo: null,
    banner_images: [],
    mobile_banner_images: []
  }
}
