export const PAYMENT_STATUS_PENDING = 'pending'
export const PAYMENT_STATUS_IN_PROGRESS = 'in_progress'
export const PAYMENT_STATUS_SUCCESS = 'success'
export const PAYMENT_STATUS_FAILURE = 'failure'
export const PAYMENT_STATUS_EXPIRED = 'expired'
export const PAYMENT_STATUS_REFUND = 'refund'
export const PAYMENT_STATUS_CANCELLED = 'cancelled'
export const PAYMENT_STATUS_PENDING_DELIVERY = 'pending_delivery'

const PAYMENT_STATUSES = {
  [PAYMENT_STATUS_PENDING]: 'Pago pendiente',
  [PAYMENT_STATUS_IN_PROGRESS]: 'Pago pendiente',
  [PAYMENT_STATUS_SUCCESS]: 'Pago aprobado',
  [PAYMENT_STATUS_FAILURE]: 'Pago rechazado',
  [PAYMENT_STATUS_EXPIRED]: 'Pago expirado',
  [PAYMENT_STATUS_REFUND]: 'Pago devuelto',
  [PAYMENT_STATUS_CANCELLED]: 'Pago cancelado',
  [PAYMENT_STATUS_PENDING_DELIVERY]: 'Pago contraentrega'
}

export function getPaymentStatus (name) {
  return PAYMENT_STATUSES[name] ? PAYMENT_STATUSES[name] : name
}
