import { INTERNAL_SERVER_ERROR, UNEXPECTED_ERROR } from '@/shared/errors'
import { STATUS_INTERNAL_SERVER_ERROR } from '@/shared/httpStatuses'

const logger = require('pino')()

export function setLoading (state, loading) {
  state.loading = loading
}

export function setError (state, error) {
  if (!error) {
    state.error = error
    return
  }

  if (!error.response) {
    state.error = error
    logger.error(error)

    return
  }

  if (error.response.status === STATUS_INTERNAL_SERVER_ERROR) {
    state.error = {
      code: STATUS_INTERNAL_SERVER_ERROR,
      message: INTERNAL_SERVER_ERROR
    }

    logger.error(error)
    return
  }

  const code = error.response.data.code
  const message = error.response.data.message

  state.error = {
    code: code || error.response.status,
    message: message || UNEXPECTED_ERROR
  }

  logger.error(error.response.data)
}
