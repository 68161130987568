import { WEBSOCKETS_BASE_API, STORAGE_TOKEN_NAME } from '@/config/constants'

const EVENT_TYPE_NOTIFICATION_CREATED = 'notification_created'

const PROCESS_EVENT_TYPE = {
  [EVENT_TYPE_NOTIFICATION_CREATED]: processNotification
}

export async function createConnection ({ commit }) {
  const token = localStorage.getItem(STORAGE_TOKEN_NAME)
  if (!token) {
    return
  }

  const url = new URL(WEBSOCKETS_BASE_API)
  url.searchParams.append('api-key', token)

  const connection = await new WebSocket(url.toString())

  connection.onopen = function () {
    commit('setConnection', connection)
  }

  connection.onmessage = function (event) {
    processWSMessage(commit, event)
  }

  connection.onerror = function (event) {
    console.error('websockets - failed to start connection', event)
  }

  connection.onclose = function () {
    commit('setConnection', null)
  }
}

export function closeConnection ({ commit }) {
  commit('closeConnection')
}

function processWSMessage (commit, event) {
  const response = JSON.parse(event.data)

  const process = PROCESS_EVENT_TYPE[response.event_name]
  if (!process) {
    console.error('websockets - invalid event type', response.event_name)
    return
  }

  process(commit, response)
}

function processNotification (commit, { data }) {
  commit('notification/addNotification', data, { root: true })

  const notification = { message: data.message, color: 'var(--app-menu-color-active)', y: 'top', x: 'right', buttonColor: '#FFFFFF' }
  commit('notification/showNotification', notification, { root: true })
}
