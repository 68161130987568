import { currency, dateFormat } from '@/shared/format'
import { DELIVERY_STATUS_PENDING, DELIVERY_STATUS_IN_PROGRESS, DELIVERY_STATUS_DELIVERED, DELIVERY_STATUS_RETURNED } from '@/shared/delivery'
import productNotFound from '@/assets/product-not-found.png'

export function getOrdersTotalPrice (state) {
  return function (index) {
    const order = state.orders.orders[index]

    const productCost = getOrderProductPrice(order.products)
    const deliveryCost = order.delivery.cost

    return currency(productCost + deliveryCost)
  }
}

export function getOrderTotalPrice (state) {
  const order = state.orderDetail

  const productCost = getOrderProductPrice(order.products)
  const deliveryCost = order.delivery.cost

  return currency(productCost + deliveryCost)
}

export function getOrderProductTotalPrice (state) {
  const products = state.orderDetail.products
  const productCost = getOrderProductPrice(products)

  return currency(productCost)
}

function getOrderProductPrice (products) {
  return products.reduce((cost, product) => {
    let price = product.price
    if (product.variant) {
      price = product.variant.price
    }

    return (parseFloat(price) * product.quantity) + cost
  }, 0)
}

export function getProductCoverImage (state) {
  return function (index) {
    const product = state.orderDetail.products[index]
    if (hasImages(product)) {
      return product.image_urls[0]
    }

    if (!product.variants) {
      return productNotFound
    }

    for (const variant of product.variants) {
      if (hasImages(variant)) {
        return variant.image_urls[0]
      }
    }

    return productNotFound
  }
}

function hasImages (product) {
  const images = product.image_urls

  return !!images && images.length > 0
}

export function getProductPrice (state) {
  return function (index) {
    const product = state.orderDetail.products[index]
    if (product.variant) {
      return currency(parseFloat(product.variant.price) * product.quantity)
    }

    return currency(parseFloat(product.price) * product.quantity)
  }
}

export function getProductHumanizedQuantity (state) {
  return function (index) {
    const product = state.orderDetail.products[index]

    return humanizedQuantity(product.quantity)
  }
}

function humanizedQuantity (quantity) {
  if (quantity === 0) {
    return quantity
  }

  const text = quantity === 1 ? 'Unidad' : 'Unidades'

  return quantity + ' ' + text
}

export function getOrdersDeliveryStatus (state) {
  return function (index) {
    const deliveryRecords = state.orders.orders[index].delivery_records
    if (!deliveryRecords || deliveryRecords.length === 0) {
      return DELIVERY_STATUS_PENDING
    }

    const len = deliveryRecords.length

    return deliveryRecords[len - 1].status
  }
}

export function getDeliveryByStatus (state) {
  const deliveryRecords = state.orderDetail.delivery_records
  if (!deliveryRecords) {
    return [{
      status: DELIVERY_STATUS_PENDING,
      details: [
        { creation_date: state.orderDetail.creation_date }
      ]
    }]
  }

  const deliveryRecordsByStatus = []
  for (const record of deliveryRecords) {
    const deliveryRecord = deliveryRecordsByStatus.find(element => element.status === record.status)
    if (deliveryRecord) {
      deliveryRecord.details.push({
        delivery_record_id: record.delivery_record_id,
        description: record.description,
        creation_date: record.creation_date
      })

      continue
    }

    deliveryRecordsByStatus.push({
      status: record.status,
      details: [{
        delivery_record_id: record.delivery_record_id,
        description: record.description,
        creation_date: record.creation_date
      }]
    })
  }

  return deliveryRecordsByStatus
}

export function getDeliveryLastRecord (state) {
  const deliveryRecords = state.orderDetail.delivery_records
  if (!deliveryRecords || deliveryRecords.length === 0) {
    return null
  }

  const len = deliveryRecords.length

  return deliveryRecords[len - 1]
}

export function getDeliveryLastStatus (state) {
  const lastRecord = getDeliveryLastRecord(state)
  if (!lastRecord) {
    return null
  }

  return lastRecord.status
}

export function getDeliveryTitle (state) {
  const record = getDeliveryLastRecord(state)
  if (!record || record.status === DELIVERY_STATUS_PENDING) {
    return 'Tu pedido en preparación'
  }

  if (record.status === DELIVERY_STATUS_IN_PROGRESS) {
    return 'Tu pedido en camino'
  }

  if (record.status === DELIVERY_STATUS_DELIVERED) {
    return 'Entregado el ' + dateFormat(record.creation_date, 'D [de] MMM')
  }

  if (record.status === DELIVERY_STATUS_RETURNED) {
    return 'Devuelto el ' + dateFormat(record.creation_date, 'D [de] MMM')
  }
}
