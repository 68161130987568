// router-name: config
const LIST_CONFIG = {
  brands: newListConfig('Marcas', 'create-brand'),
  products: newListConfig('Productos', 'create-product'),
  'delivery-zones': newListConfig('Zonas de envió', 'create-delivery-zone'),
  orders: newListConfig('Ventas', null),
  collections: newListConfig('Solicitudes de recogida', 'create-collection-request')
}

function newListConfig (title, createRouteName) {
  return {
    title,
    createRouteName
  }
}

export function getListConfig (name) {
  return LIST_CONFIG[name] ? LIST_CONFIG[name] : null
}

export function getFirstLetter (name) {
  const cleanName = name.replace(/\s+/g, ' ').trim()
  const splitName = cleanName.split(' ')
  const firstLetter = splitName[0].charAt(0)

  return firstLetter || 'X'
}

export function buildListURL (baseURL, search = undefined) {
  if (!search) {
    return baseURL
  }

  const query = baseURL.includes('?') ? '&' : '?'
  search = encodeURIComponent(search)

  return `${baseURL}${query}search=${search}`
}
