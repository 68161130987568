import Vue from 'vue'

export async function signIn ({ commit }, user) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'POST',
      url: '/v1/customers/auth',
      data: user
    })

    commit('auth/login', data, { root: true })
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function resetPassword ({ commit }, user) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    await Vue.axios({
      method: 'POST',
      url: '/v1/customers/reset-password',
      data: user
    })
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function changePassword ({ commit }, user) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    await Vue.axios({
      method: 'PUT',
      url: '/v1/customers/reset-password',
      data: user
    })
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export function logout ({ commit }) {
  commit('logout')
}
