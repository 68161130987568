export function changeDrawer (state) {
  state.drawer = !state.drawer
}

export function setDrawer (state, drawer) {
  state.drawer = drawer
}

export function changeMini (state) {
  state.mini = !state.mini
}

export function activateMenuItem (state, routeName) {
  for (const item of state.items) {
    if (activateChild(item, routeName)) {
      item.active = true
    }
  }
}

function activateChild (item, routeName) {
  for (const child of item.children) {
    if (child.route_name === routeName) {
      child.active = true
      return true
    }

    if (activateGrandchild(child, routeName)) {
      child.active = true
      return true
    }
  }

  return false
}

function activateGrandchild (item, routeName) {
  if (!item.children) {
    return false
  }

  for (const child of item.children) {
    if (child.route_name === routeName) {
      child.active = true
      return true
    }
  }

  return false
}

export function deactivateMenuItem (state, routeName) {
  for (const item of state.items) {
    const deactivated = deactivateChild(item, routeName)
    if (deactivated) {
      break
    }
  }
}

export function deactivateChild (item, routeName) {
  for (const child of item.children) {
    if (child.route_name === routeName) {
      child.active = false
      return true
    }

    const deactivated = deactivateGrandchild(child, routeName)
    if (deactivated) {
      return true
    }
  }

  return false
}

export function deactivateGrandchild (item, routeName) {
  if (!item.children) {
    return false
  }

  for (const child of item.children) {
    if (child.route_name === routeName) {
      child.active = false
      return true
    }
  }

  return false
}
