import Vue from 'vue'
import VueRouter from 'vue-router'
import { STORAGE_TOKEN_NAME } from '@/config/constants'
import { authenticationIsRequired, hideIfAuthenticated, validateChangePassword } from '@/router/validations'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'products',
        component: () => import(/* webpackChunkName: "empty" */ '@/views/Empty/EmptyView.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: '/',
            name: 'products',
            component: () => import(/* webpackChunkName: "products" */ '@/views/Product/Products.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'create',
            name: 'create-product',
            component: () => import(/* webpackChunkName: "create-product" */ '@/views/Product/CreateProduct.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'update/:id',
            name: 'update-product',
            component: () => import(/* webpackChunkName: "update-product" */ '@/views/Product/UpdateProduct.vue'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'account',
        component: () => import(/* webpackChunkName: "empty" */ '@/views/Empty/EmptyView.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: '/',
            name: 'account',
            component: () => import(/* webpackChunkName: "account" */ '@/views/Account/Account.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'payment-gateway',
            name: 'payment-gateway-auth',
            component: () => import(/* webpackChunkName: "payment-gateway-auth" */ '@/views/Account/PaymentAuthorization.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'payment-gateway/auth',
            name: 'payment-gateway-link-account',
            component: () => import(/* webpackChunkName: "payment-gateway-link-account" */ '@/views/Account/PaymentLinkAccount.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'theme',
            name: 'theme',
            component: () => import(/* webpackChunkName: "payment-gateway-link-account" */ '@/views/Account/Theme.vue'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'delivery-zones',
        component: () => import(/* webpackChunkName: "empty" */ '@/views/Empty/EmptyView.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: '/',
            name: 'delivery-zones',
            component: () => import(/* webpackChunkName: "delivery-zones" */ '@/views/Delivery/DeliveryZones.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'create',
            name: 'create-delivery-zone',
            component: () => import(/* webpackChunkName: "create-delivery-zone" */ '@/views/Delivery/CreateDeliveryZone.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'update/:id',
            name: 'update-delivery-zone',
            component: () => import(/* webpackChunkName: "update-delivery-zone" */ '@/views/Delivery/UpdateDeliveryZone.vue'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'orders',
        component: () => import(/* webpackChunkName: "empty" */ '@/views/Empty/EmptyView.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: '/',
            name: 'orders',
            component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/Orders.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: ':id',
            name: 'order-detail',
            component: () => import(/* webpackChunkName: "order" */ '@/views/Order/Order.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: ':id/delivery',
            name: 'delivery',
            component: () => import(/* webpackChunkName: "shipment" */ '@/views/Order/Delivery.vue'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'collections',
        component: () => import(/* webpackChunkName: "empty" */ '@/views/Empty/EmptyView.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: '/',
            name: 'collections',
            component: () => import(/* webpackChunkName: "collections" */ '@/views/Collection/Collections.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'create',
            name: 'create-collection-request',
            component: () => import(/* webpackChunkName: "create-collection-request" */ '@/views/Collection/CreateCollection.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'view/:id',
            name: 'view-collection-request',
            component: () => import(/* webpackChunkName: "view-collection-request" */ '@/views/Collection/ViewCollection.vue'),
            meta: { requiresAuth: true }
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: () => import(/* webpackChunkName: "auth" */ '@/layouts/AuthLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Auth/Login.vue'),
        meta: { hideInSession: true }
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "forgot-password" */ '@/views/Auth/ForgotPassword.vue'),
        meta: { hideInSession: true }
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: () => import(/* webpackChunkName: "change-password" */ '@/views/Auth/ChangePassword.vue'),
        meta: { hideInSession: true }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function shouldRedirectAfterLogin (route) {
  return route.meta && route.meta.requiresAuth && route.fullPath !== '/'
}

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem(STORAGE_TOKEN_NAME)
  if (authenticationIsRequired(to, isAuthenticated)) {
    if (shouldRedirectAfterLogin(to)) {
      return next({ name: 'login', query: { redirect: to.fullPath } })
    }

    return next({ name: 'login' })
  }

  if (hideIfAuthenticated(to, isAuthenticated)) {
    return next({ name: 'home' })
  }

  if (!validateChangePassword(to)) {
    return next({ name: 'forgot-password' })
  }

  next()
})

export default router
