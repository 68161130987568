import { currency } from '@/shared/format'

export function getCollectionOrderPrice (state) {
  return function (index) {
    const collection = state.collectionDetail
    if (!collection) {
      return null
    }

    const order = collection.orders[index]

    const productCost = getOrderProductPrice(order.products)
    const deliveryCost = order.delivery.cost

    return currency(productCost + deliveryCost)
  }
}

function getOrderProductPrice (products) {
  return products.reduce((cost, product) => {
    let price = product.price
    if (product.variant) {
      price = product.variant.price
    }

    return (parseFloat(price) * product.quantity) + cost
  }, 0)
}
