export function addUploadFile (state, file) {
  state.files.push(file)
}

export function deleteUploadFile (state, name) {
  const index = state.files.findIndex(element => element.name === name)
  if (index === -1) {
    return
  }

  state.files.splice(index, 1)
}
