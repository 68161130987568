export const DELIVERY_PROVIDER_AVEONLINE = 'aveonline'

export const DELIVERY_STATUS_PENDING = 'pending'
export const DELIVERY_STATUS_IN_PROGRESS = 'in-progress'
export const DELIVERY_STATUS_DELIVERED = 'delivered'
export const DELIVERY_STATUS_RETURNED = 'returned'

export const CARRIER_ID_ENVIA = '29'
export const CARRIER_ID_COORDINADORA = '1009'
export const CARRIER_ID_SERVIENTREGA = '33'
export const CARRIER_ID_SAFERBO = '1022'
export const CARRIER_ID_DOMINA = '1026'
export const CARRIER_ID_TCC = '1010'
export const CARRIER_ID_MOOVA = '1027'

const DELIVERY_STATUS_NAME = {
  [DELIVERY_STATUS_PENDING]: 'En preparación',
  [DELIVERY_STATUS_IN_PROGRESS]: 'En camino',
  [DELIVERY_STATUS_DELIVERED]: 'Entregado',
  [DELIVERY_STATUS_RETURNED]: 'Devuelto'
}

const DELIVERY_STATUS_DEFAULT_DESCRIPTION = {
  [DELIVERY_STATUS_PENDING]: 'La tienda está preparando tu paquete.',
  [DELIVERY_STATUS_IN_PROGRESS]: 'Tu paquete está en viaje.',
  [DELIVERY_STATUS_DELIVERED]: 'Entregamos el paquete.',
  [DELIVERY_STATUS_RETURNED]: 'Se realizó la devolución del paquete.'
}

const DELIVERY_STATUS_TITLE = {
  [DELIVERY_STATUS_IN_PROGRESS]: 'Despachado',
  [DELIVERY_STATUS_DELIVERED]: 'Entregado',
  [DELIVERY_STATUS_RETURNED]: 'Devuelto'
}

export const DELIVERY_STATUSES = [
  {
    key: DELIVERY_STATUS_PENDING,
    name: DELIVERY_STATUS_NAME[DELIVERY_STATUS_PENDING]
  },
  {
    key: DELIVERY_STATUS_IN_PROGRESS,
    name: DELIVERY_STATUS_NAME[DELIVERY_STATUS_IN_PROGRESS]
  },
  {
    key: DELIVERY_STATUS_DELIVERED,
    name: DELIVERY_STATUS_NAME[DELIVERY_STATUS_DELIVERED]
  },
  {
    key: DELIVERY_STATUS_RETURNED,
    name: DELIVERY_STATUS_NAME[DELIVERY_STATUS_RETURNED]
  }
]

export const CARRIER_INFO = {
  [CARRIER_ID_ENVIA]: {
    name: 'Envia',
    logo: 'https://app.aveonline.co/app/temas/imagen_transpo/084935-1-envia-094632-1-ENVIA.jpg',
    logo_mini: 'https://app.aveonline.co/app/temas/imagen_transpo/121748-2-envia.png'
  },
  [CARRIER_ID_COORDINADORA]: {
    name: 'Coordinadora',
    logo: 'https://app.aveonline.co/app/temas/imagen_transpo/045634-1-coordinadora_1.jpg',
    logo_mini: 'https://app.aveonline.co/app/temas/imagen_transpo/122030-2-coordinadora.png'
  },
  [CARRIER_ID_SERVIENTREGA]: {
    name: 'Servientrega',
    logo: 'https://app.aveonline.co/app/temas/imagen_transpo/104638-1-Logo-Servientrega.jpg',
    logo_mini: 'https://app.aveonline.co/app/temas/imagen_transpo/122021-2-servientrega.png'
  },
  [CARRIER_ID_SAFERBO]: {
    name: 'Saferbo',
    logo: 'https://app.aveonline.co/app/temas/imagen_transpo/045506-1-logo saferbo.png',
    logo_mini: 'https://app.aveonline.co/app/temas/imagen_transpo/122149-2-saferbo.png'
  },
  [CARRIER_ID_DOMINA]: {
    name: 'Domina',
    logo: 'https://app.aveonline.co/app/temas/imagen_transpo/095828-1-LogoDomina.png',
    logo_mini: 'https://app.aveonline.co/app/temas/imagen_transpo/122246-2-domina.png'
  },
  [CARRIER_ID_TCC]: {
    name: 'TCC',
    logo: 'https://app.aveonline.co/app/temas/imagen_transpo/104926-1-tcc.jpg',
    logo_mini: 'https://app.aveonline.co/app/temas/imagen_transpo/122044-2-tcc.png'
  },
  [CARRIER_ID_MOOVA]: {
    name: 'Moova'
  }
}

export function getDeliveryStatusName (status) {
  return DELIVERY_STATUS_NAME[status] ? DELIVERY_STATUS_NAME[status] : status
}

export function getDeliveryStatusDescription (description, status) {
  if (description) {
    return description
  }

  return DELIVERY_STATUS_DEFAULT_DESCRIPTION[status] ? DELIVERY_STATUS_DEFAULT_DESCRIPTION[status] : description
}

export function getDeliveryStatusTitle (status) {
  return DELIVERY_STATUS_TITLE[status] ? DELIVERY_STATUS_TITLE[status] : status
}

export function getCarrierInfo (id) {
  return CARRIER_INFO[id] ? CARRIER_INFO[id] : null
}
