import moment from 'moment'
moment.locale('es-do')

export function dateFormat (value, format) {
  if (!value) {
    return 'N/A'
  }

  return moment(value, 'YYYY-MM-DD h:mm:ss').format(format)
}

export function currency (value) {
  if (!value) {
    return
  }

  const result = parseFloat(value).toFixed(0).replace('.', ',')
  return '$ ' + result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export async function newSha256 (text) {
  const utf8 = new TextEncoder().encode(text)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')

  return hashHex
}
