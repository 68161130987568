import Vue from 'vue'
import { tokenToJSON } from '@/shared/token'

export async function getCustomer ({ commit }) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setCustomer', null)

    const jsonToken = tokenToJSON()
    if (!jsonToken) {
      throw new Error('token not found')
    }

    const { data } = await Vue.axios({
      method: 'GET',
      url: `/v1/customers/${jsonToken.customer_id}`
    })

    commit('setCustomer', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function updateCustomer ({ commit }, customer) {
  try {
    commit('shared/setError', false, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'PUT',
      url: `/v1/customers/${customer.customer_id}`,
      data: customer
    })
    commit('setCustomer', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}
