export function getAbbreviatedName (state) {
  const companyName = state.customer.company_name.replace(/\s+/g, ' ').trim()

  const splitName = companyName.split(' ')
  if (splitName.length === 1) {
    const name = splitName[0]

    return (name.charAt(0) + name.charAt(name.length - 1)).toUpperCase()
  }

  return (splitName[0].charAt(0) + splitName[1].charAt(0)).toUpperCase()
}
