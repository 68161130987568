export default {
  drawer: false,
  mini: false,
  items: [
    // {
    //   icon: 'home',
    //   title: 'Home',
    //   route_name: 'home',
    //   active: false
    // },
    {
      title: 'Cuenta',
      active: false,
      children: [
        {
          title: 'Perfil',
          route_name: 'account',
          active: false
        },
        {
          title: 'Pasarela de Pago',
          route_name: 'payment-gateway-auth',
          active: false,
          children: [
            {
              title: 'Confirmar Vinculación',
              route_name: 'payment-gateway-link-account',
              active: false
            }
          ]
        },
        {
          title: 'Tema',
          route_name: 'theme',
          active: false
        }
      ]
    },
    {
      title: 'Productos',
      active: false,
      children: [
        {
          title: 'Categorias',
          route_name: 'home',
          active: false
        },
        {
          title: 'Productos',
          route_name: 'products',
          active: false,
          children: [
            {
              title: 'Crear producto',
              route_name: 'create-product'
            },
            {
              title: 'Actualizar producto',
              route_name: 'update-product'
            }
          ]
        }
      ]
    },
    {
      title: 'Enviós',
      active: false,
      children: [
        {
          title: 'Zonas de Envió',
          route_name: 'delivery-zones',
          active: false,
          children: [
            {
              title: 'Crear zona de envió',
              route_name: 'create-delivery-zone'
            },
            {
              title: 'Actualizar zona de envió',
              route_name: 'update-delivery-zone'
            }
          ]
        }
      ]
    },
    {
      title: 'Ventas',
      active: false,
      children: [
        {
          title: 'Ventas',
          route_name: 'orders',
          active: false,
          children: [
            {
              title: 'Detalle de la venta',
              route_name: 'order-detail'
            },
            {
              title: 'Detalle de la entrega',
              route_name: 'delivery'
            }
          ]
        },
        {
          title: 'Solicitud de recogidas',
          route_name: 'collections',
          active: false,
          children: [
            {
              title: 'Crear solicitud de recogida',
              route_name: 'create-collection-request'
            },
            {
              title: 'Ver solicitud de recogida',
              route_name: 'view-collection-request'
            }
          ]
        }
      ]
    }
  ]
}
