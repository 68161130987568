import { STORAGE_TOKEN_NAME } from '@/config/constants'

export function login (state, session) {
  state.isAuthenticated = true
  localStorage.setItem(STORAGE_TOKEN_NAME, session.access_token)
}

export function logout (state) {
  state.isAuthenticated = false
  localStorage.removeItem(STORAGE_TOKEN_NAME)
}
