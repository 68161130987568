import Vue from 'vue'
import { buildListURL } from '@/lists/lists'

export async function getCollections ({ commit }, search = undefined) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setCollections', null)

    const url = buildListURL('/v1/orders/collections', search)
    const { data } = await Vue.axios({
      url
    })

    commit('setCollections', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getMoreCollections ({ commit }, url) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      url
    })

    commit('addCollections', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getCollection ({ commit }, collectionID) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setCollection', null)

    const { data } = await Vue.axios({
      method: 'GET',
      url: `/v1/orders/collections/${collectionID}`
    })

    commit('setCollection', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function createCollection ({ commit }, collection) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'POST',
      url: '/v1/orders/collections',
      data: collection
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}
