import { STORAGE_TOKEN_NAME } from '@/config/constants'

export const tokenToJSON = () => {
  const token = localStorage.getItem(STORAGE_TOKEN_NAME)
  if (!token) {
    return
  }

  const tokeURL = token.split('.')[1]

  return JSON.parse(atob(tokeURL))
}
