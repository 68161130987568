export function setConnection (state, connection) {
  state.connection = connection
}

export function closeConnection (state) {
  if (state.connection) {
    state.connection.close()
    state.close = null
  }
}
