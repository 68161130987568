import Vue from 'vue'
import { STATUS_NOT_FOUND } from '@/shared/httpStatuses'

export async function getTheme ({ commit }) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setTheme', null)

    const { data } = await Vue.axios({
      method: 'GET',
      url: '/v1/themes/detail'
    })

    commit('setTheme', data)
  } catch (error) {
    if (error.response.status === STATUS_NOT_FOUND) {
      commit('setDefaultTheme')
      return
    }

    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function saveTheme ({ commit }, theme) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'POST',
      url: '/v1/themes',
      data: theme
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}
