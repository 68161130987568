import Vue from 'vue'
import { buildListURL } from '@/lists/lists'

export async function getRegions ({ commit }, withCities = undefined) {
  try {
    commit('shared/setError', false, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setRegions', null)

    let url = '/v1/regions'
    if (withCities) {
      url = url + '?with_cities=' + encodeURIComponent(withCities)
    }

    const { data } = await Vue.axios({
      url
    })

    commit('setRegions', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getCities ({ commit }, departmentID) {
  try {
    commit('shared/setError', false, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setCities', null)

    const { data } = await Vue.axios({
      url: `/v1/regions/${departmentID}`
    })

    commit('setCities', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getDeliveryZones ({ commit }, search = undefined) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setDeliveryZones', null)

    const url = buildListURL('/v1/delivery-zones', search)
    const { data } = await Vue.axios({
      url
    })

    commit('setDeliveryZones', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getMoreDeliveryZones ({ commit }, url) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      url
    })

    commit('addDeliveryZones', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function getDeliveryZone ({ commit }, deliveryZoneID) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })
    commit('setDeliveryZone', null)

    const { data } = await Vue.axios({
      method: 'GET',
      url: `/v1/delivery-zones/${deliveryZoneID}`
    })

    commit('setDeliveryZone', data)
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function createDeliveryZone ({ commit }, deliveryZone) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'POST',
      url: '/v1/delivery-zones',
      data: deliveryZone
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}

export async function updateDeliveryZone ({ commit }, deliveryZone) {
  try {
    commit('shared/setError', null, { root: true })
    commit('shared/setLoading', true, { root: true })

    const { data } = await Vue.axios({
      method: 'PUT',
      url: `/v1/delivery-zones/${deliveryZone.delivery_zone_id}`,
      data: deliveryZone
    })

    return data
  } catch (error) {
    commit('shared/setError', error, { root: true })
  } finally {
    commit('shared/setLoading', false, { root: true })
  }
}
